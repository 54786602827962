import { default as dayjs } from 'dayjs';
import * as Regex from './Regex';
import { words } from './language/es';
import { useLayoutEffect, useState } from 'react';
import { IUserData } from '@/@types/user';

import { setDefaultOptions, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { IInterest } from '@/@types/interest';

setDefaultOptions({ locale: es });

type CurrencyFormat = {
    value: number | undefined;
    exact?: boolean;
};

export const CurrencyFormat = ({ value, exact }: CurrencyFormat) => {
    if (!value) return value;
    const max = exact
        ? {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
          }
        : null;
    let currency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        ...max,
    });

    return currency.format(value);
};

export const toWords = (value: string, type: string) => {
    if (!value) {
        return value;
    }

    const tempCats = type.split('.');
    const tempCatMain = tempCats[0];
    const tempCatSub = tempCats[1];

    if (!tempCatSub) return words[tempCatMain][value] ?? value;
    return words[tempCatMain][tempCatSub][value];
};

export function htmlToString(value: string) {
    if (!value) return '';
    const tempValue = JSON.stringify(value).replace(/(\\r)*\\n/g, '<br>');
    return tempValue.substring(1, tempValue.length - 1);
}

export function formatDate(date: Date, formatDate: string) {
    if (typeof formatDate === 'string')
        return format(dayjs(date).toDate(), formatDate);
    else return format(dayjs(date).toDate(), 'dd/MMM/yyyy');
}

export function getBeneficiaryType(value: string) {
    const types: {
        [key: string]: string;
    } = {
        'significant-other': 'Esposo / Esposa',
        'son-daughter': 'Hijo / Hija',
        'father-mother': 'Padre / Madre',
        'uncle-aunt': 'Tío / Tía',
        'brother-sister': 'Hermano / Hermana',
        friend: 'Amigo / Amiga',
        other: 'Otro',
        'grandpa-grandma': 'Abuelo / Abuela',
        cousin: 'Primo / Prima',
    };
    return types[value];
}
//This is the method I use for detecting actual  mouse position
export default function useMousePosition() {
    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    useLayoutEffect(() => {
        function updatePosition(e: any) {
            setPosition({ x: e.clientX, y: e.clientY });
        }
        document.addEventListener('mousemove', updatePosition);
        return () => document.removeEventListener('mousemove', updatePosition);
    }, []);

    return position;
}

export function userNameFormat(user: IUserData) {
    return (
        user.name +
        ' ' +
        (user.last_name || '') +
        ' ' +
        (user.mother_last_name || '')
    );
}

export function userDirectionFormat(user: IUserData) {
    if (user.investor.address && user.investor.address.street) {
        return (
            <>
                <p>
                    {user.investor.address.street}{' '}
                    {user.investor.address.street_number +
                        user.investor.address.apartment_number}
                </p>
                <p>
                    {user.investor.address.locality},{' '}
                    {user.investor.address.municipality},{' '}
                    {user.investor.address.state}
                </p>
            </>
        );
    }
}

export function uppercase(text: string) {
    return text.toUpperCase();
}

export function removeAccentsAndEnye(text: string) {
    const accents: { [key: string]: string } = {
        á: 'a',
        é: 'e',
        í: 'i',
        ó: 'o',
        ú: 'u',
        Á: 'A',
        É: 'E',
        Í: 'I',
        Ó: 'O',
        Ú: 'U',
    };
    return text
        .split('')
        .map(letter => accents[letter] || letter)
        .join('');
}

export const handleGetCampaign = (projectName: string) => {
    const nameSplit = projectName.split(' ');
    const regex = /V\d/;

    const findVersion = nameSplit.find(itm => regex.test(itm));
    if (findVersion) return 'Campaña ' + findVersion.replace('V', '');
    return 'Campaña 1';
};

export const handleRemoveCampaign = (projectName: string) => {
    const nameSplit = projectName.split(' ');
    const regex = /V\d/;

    const findVersion = nameSplit.find(itm => regex.test(itm));
    if (findVersion) return projectName.replace(findVersion, '');
    return projectName;
};

const handleGetColor = (el: IInterest) => {
    const { confirmedDate, scheduledDate } = el.payment;
    if (confirmedDate) {
        if (dayjs(confirmedDate).isAfter(dayjs(scheduledDate).add(7, 'day'))) {
            return '#EABE6C';
        }
    }else if (dayjs(scheduledDate).add(2, 'day').isBefore(dayjs())) {
            return '#FA7070';
        }
    

    if (el.type == 'capital') {
        return el.status == 'completed'
            ? '#233E8B'
            : '#BEBEDE';
    } else {
        return el.status == 'completed' ? '#5F6CAF': '#BEBEDE'
    }
}

export const handleConvertNextPayments = (data: []) => {
    const newData = data.filter((el: IInterest) => el.type != 'prefunding');

    const temp = newData.map((el: IInterest) => {
        if (el) {
            return {
                id: el._id,
                type: toWords(el.type, 'interest.type'),
                amount: el.amount,
                date: dayjs(el.payment.scheduledDate).format('YYYY-MM-DD'),
                realDate: el.payment.confirmedDate
                    ? dayjs(el.payment.confirmedDate).format('YYYY-MM-DD')
                    : '',
                status: el.status,
                number: el.number,
                total: el.total,
                delayed: el.payment.confirmedDate
                    ? dayjs(el.payment.confirmedDate).isAfter(
                          dayjs(el.payment.scheduledDate).add(7, 'day'),
                      )
                    : dayjs(el.payment.scheduledDate)
                          .add(2, 'day')
                          .isBefore(dayjs()),
                days: el.payment.confirmedDate
                    ? dayjs(el.payment.confirmedDate).diff(
                          dayjs(el.payment.scheduledDate).add(3, 'day'), 'day'
                      )
                    : dayjs(el.payment.scheduledDate)
                          .add(2, 'day')
                          .diff(dayjs(), 'day'),
                color: handleGetColor(el),
                investment: el.investment._id,
                reason: el.reason,
            };
        }
    });
    return temp.reverse();
};


export { Regex };
