export const words: any = {
    project: {
        status: {
            draft: 'Borrador',
            soon: 'Próximamente',
            prefunding: 'Pre-fondeo',
            opportunity: 'Oportunidad',
            documentation: 'Documentación',
            ongoing: 'En curso' /* Se disparan intereses */,
            closing: 'Finalizando fondeo',
            completed: 'Finalizado',
            cancelled: 'Cancelado',
            collection: 'Proceso de Cobranza',
        },
        sector: {
            residential: 'Residencial',
            'residential-plus': 'Residencial Plus',
            commercial: 'Comercial',
            industrial: 'Industrial',
        },
        rate: {
            rate: 'Tasa Anual Simple',
            'expected-return': 'Rendimiento Esperado',
            'initial-return': 'Tasa Anual Simple Inicial',
            'rate-complex': 'Tasa Anual Compuesta',
        },
        type: {
            new: 'Nuevo',
            remodel: 'Remodelación',
        },
        payment_type: {
            monthly: 'Mensual',
            bimonthly: 'Bimestral',
            trimonthly: 'Trimestral',
            quarterly: 'Cuatrimestral',
            biannual: 'Semestral',
            annual: 'Anual',
            ending: 'Al final del plazo',
        },
        payment_method: {
            spei: 'Sistema de Pagos Electrónicos Interbancarios',
        },
        funding: {
            debt: 'Deuda',
            presale: 'Preventa',
            patrimonial: 'Patrimonial',
            coinvestment: 'Coinversión',
        },
        capital_payment: {
            end: 'Al final del plazo',
            start: 'Amortización de capital',
        },
    },
    investment: {
        status: {
            pending: 'Borrador',
            'pending-deposit': 'Pendiente',
            paid: 'Finalizada',
            completed: 'Completada',
            expired: 'Vencida',
            cancelled: 'Cancelada',
            'capital-deploy': 'En Curso',
            contact: 'Contactar',
            collection: 'Proceso de Cobranza',
            'cancelled-not-funds': 'Cancelada',
        },
    },
    user: {
        person_type: {
            '1': 'Persona física',
            '2': 'Persona moral',
            '3': 'Persona física con actividad empresarial',
        },
        status: {
            unverified: 'Sin Verificar',
            migrated: 'Migración',
            incomplete: 'Incompleto',
            complete: 'Completo',
            normal: 'Normal',
            'account-verification': 'Pendiente Verificación',
            'account-blocked-limit': 'Bloqueado (Límite)',
            'account-blocked-admin': 'Bloqueado (Admin)',
        },
        level: {
            basic: 'Nivel I',
            unlimited: 'Nivel II',
        },
        beneficiary: {
            'significant-other': 'Esposo / Esposa',
            'son-daughter': 'Hijo / Hija',
            'father-mother': 'Padre / Madre',
            'uncle-aunt': 'Tío / Tía',
            'brother-sister': 'Hermano / Hermana',
            friend: 'Amigo / Amiga',
            other: 'Otro',
            'grandpa-grandma': 'Abuelo / Abuela',
            cousin: 'Primo / Prima',
        },
        experience_type: {
            stock: 'Bolsa / Acciones',
            escrow: 'Fibras',
            cetes: 'CETES',
            retirement: 'Afores',
            commodities: 'Commodities',
            debt_instrument: 'Instrumentos de Deuda',
            real_estate: 'Bienes Raíces',
            startups: 'Startups',
            other: 'Otros Productos Financieros',
            never: 'No he realizado ninguna inversión',
        },
        debWallet: {
            paid: 'Pagado',
            aware: 'Al corriente',
            ongoing: 'En levantamiento',
            documentation: 'En documentación',
            latePayment90: 'Retraso <90 Días',
            latePayment365: 'Retraso 90 a 365 Días',
            latePaymentM365: 'Retraso >365 Días',
        },
    },
    financing: {
        status: {
            incomplete: 'Incompleta',
            'in-revision': 'En revisión',
            'awaiting-docs': 'Esperando documentación',
            'proposal-sent': 'Propuesta enviada',
            approved: 'Aprobada',
            rejected: 'Rechazada',
        },
        asset_type: {
            'residential-high': 'Residencial alto',
            'residential-middle': 'Residencial medio',
            'residential-low': 'Interés social',
            industrial: 'Industrial',
            retail: 'Comercial (Retail)',
            others: 'Otros',
            offices: 'Oficinas',
            hotels: 'Hoteles',
        },

        dwelling_type: {
            social_interest: 'Interés social',
            medium: 'Media',
            residential: 'Residencial',
            residential_plus: 'Residencial plus',
        },
        development_amount: {
            first: 'Es mi primer desarrollo',
            one_three: 'De 1 a 3 desarrollos',
            four_eight: 'De 4 a 8 desarrollos',
            eight_more: 'Mas de 8 desarrollos',
        },
        development_segment: {
            residential_horizontal: 'Residencial horizontal',
            residential_vertical: 'Residencial vertical',
            commercial: 'Comercial',
            urban: 'Infraestructura urbana (carreteras, puentes, parques, calles, presas, etc.) ',
        },
        company_age: {
            less_one: 'Menos de 1 año',
            one_three: 'De 1 a 3 años',
            three_more: 'Más de 3 años',
        },

        credit_usage: {
            building_materials: 'Compra de materiales de construcción',
            building_process: 'Obras de albañilería',
            building_permits: 'Licencias y permisos',
            other: 'Otros',
        },
        credit_term: {
            twelve_less: '12 meses o menos',
            twelve_eighteen: 'De 12 a 18 meses',
            eighteen_twentyfour: 'De 18 a 24 meses',
            twentyfour_more: 'Más de 24 meses',
        },
        credit_payment_frequency: {
            monthly: 'Mensual',
            quarterly: 'Trimestral',
        },

        credit_payment_type: {
            capital_interest: 'Pago de capital + intereses en cada pago',
            interest_first: 'Pago de intereses + capital al final',
        },
        unit_amount: {
            single: 'Single unit',
            two_ten: 'De 2 a 10',
            eleven_thirty: 'De 11 a 30',
            thirty_more: 'Más de 30',
        },
        unit_size: {
            hundred_less: 'Menos de 100 m2',
            hundred_hundredfifty: 'Entre 101 m2 y 150 m2',
            hundredfifty_twohundred: 'Entre 151 m2 y 200 m2',
            twohundred_more: 'Más de 200 m2',
        },
        property_status: {
            acquired: 'En propiedad',
            in_process: 'Por adquirirse',
            contributed: 'Aportado',
        },
        property_acquisition_time: {
            one_less: 'Menos de un mes',
            two_five: 'De 2 a 5 meses',
            five_more: 'Más de 5 meses',
        },
        project_building_stage: {
            before_start: 'Etapa previa al inicio de construcción',
            one_thirty: 'Del 1% al 30',
            thirty_seventy: 'Del 31% al 70',
            seventy_more: 'Más del 70',
        },
        project_permits: {
            existing: 'Ya cuenta con ello',
            in_process: 'Está en proceso',
            not_yet: 'No se ha iniciado la gestión',
        },

        market_study: {
            yes: 'Si',
            no: 'No',
        },
        market_study_origin: {
            third_party: 'Lo hizo un tercero',
            own: 'Nosotros lo hicimos',
        },
        market_study_status: {
            in_process:
                'Todavia no lo tenemos pero esta en proceso de ser realizado',
            not_required: 'No lo consideramos necesario',
        },
        market_experience: {
            yes: 'Si',
            no: 'No',
        },
        market_absorption: {
            one: 'Una unidad por mes',
            two_four: 'De 2 a 4 unidades por mes',
            five_more: 'Más de 5 unidades por mes',
        },

        own_capital: {
            yes: 'Si',
            no: 'No',
        },
        active_credits: {
            yes: 'Si',
            no: 'No',
        },
        other_development_sales: {
            yes: 'Si',
            no: 'No',
        },
        credit_payment_source: {
            same_presale: 'Preventas del mismo desarrollo',
            other_presale: 'Ventas de otros desarrollo',
            same_credit: 'Del mismo apalancamiento',
            other_credit: 'De otro apalancamiento',
        },
        company_finance_statements: {
            yes_audit: 'Si y están auditados',
            yes_no_audit: 'Si, sin auditar',
            no: 'No',
        },
        credit_bureau: {
            yes: 'Acepto',
            no: 'No acepto',
        },

        warranty_exists: {
            yes: 'Si',
            no: 'No',
        },
        warranty_alternative: {
            simple: 'Pagaré simple',
            solidary: 'Pagaré con obligado solidario',
            solidary_endorsment:
                'Pagaré  con obligado solidario + endoso de factura',
        },
        project_appraisal: {
            yes: 'Si',
            no: 'No',
        },

        rejection: {
            'multiple-financing':
                'Lamentamos informarte que debido a disposiciones de la Ley Para Regular Las Instituciones de Tecnología Financiera, no es posible financiar un mismo proyecto en más de una plataforma a la vez.',
            'first-development':
                'Lamentamos informarte que no podremos financiar tu proyecto dado que necesitamos tengas mayor experiencia, te invitamos a que más adelante puedas volver a aplicar en Expansive.',
            'market-analysis-missing':
                'Lamentamos no poder contribuir al financiamiento de tu proyecto dado que no cuentas con un estudio de mercado.',
            'same-credit-payment':
                'No nos es posible financiar un proyecto si tu fuente de repago es el mismo apalancamiento o con algún otro apalancamiento.',
        },
    },
    interest: {
        status: {
            pending: 'Pendiente',
            completed: 'Completado',
            rejected: 'Rechazado',
            'pending-review': 'En revisión',
            cancelled: 'Cancelado',
            blocked: 'Bloqueada',
            prepaid: 'Prepago',
        },
        type: {
            interest: 'Ordinario',
            capital: 'Capital inicial',
            'post-term': 'Extemporáneo',
            moratorium: 'Moratorio',
        },
    },
    transaction: {
        type: {
            'referral-payment': 'Pago por referido',
            'referral-deposit': 'Pago por referido',
            'moratorium-payment': 'Pago de intereses moratorios',
            'interest-conciliation': 'Conciliación de intereses',
            deposit: 'Depósito a cuenta Expansive',
            'project-investment': 'Inversión en proyecto',
            'repayment-capital': 'Repago capital proyecto',
            'prefunding-payment': 'Pago de intereses fondeo',
            'interest-payment': 'Pago de intereses ordinarios',
            'capital-payment': 'Pago de Capital',
            'post-term-payment': 'Pago de intereses extemporáneos',
            withdrawal: 'Retiro a cuenta bancaria',
            'withholding-tax': 'Retención de ISR',
            'tax-payment': 'Pago de IVA',
            'investment-cancel': 'Cancelación de Inversión',
            'bonus-gift': 'Bono de Regalo',
        },
        status: {
            pending: 'Pendiente',
            completed: 'Completado',
            rejected: 'Rechazado',
            authorized: 'Autorizado',
            'pending-review': 'En revisión',
            cancelled: 'Cancelado',
            blocked: 'Bloqueada',
        },
        entities: {
            investment: 'Inversión',
            wallet: 'Wallet',
            project: 'Proyecto',
            tax: 'Impuestos',
            'cash-deposit': 'Depósito en Efectivo (OXXO)',
            'personal-account': 'Cuenta Personal (SPEI)',
        },
    },
    payments: {
        type: {
            interest: 'Ordinario',
            capital: 'Capital inicial',
            moratorium: 'Moratorio',
            commission: 'Comisión Expansive',
        },
        status: {
            pending: 'Pendiente',
            completed: 'Confirmado',
            prepaid: 'Prepago',
            cancelled: 'Cancelado',
            paid: 'Pagado',
        },
    },
    mailing: {
        type: {
            'new-referral': 'Nuevo Referido',
            'reupload-documents': 'Vuelve a Subir Documentos',
            'unlimited-confirmed': 'Aprobación Nivel II',
            'campaign-completed': 'Campaña Completada',
            'account-restricted': 'Límite Transferencias',
            'expansive-welcome': 'Bienvenida Expansive',
            'email-token-confirm': 'Token Alta Cuenta',
            'financing-status': 'Estatus Financiamiento',
            'funding-rejected': 'Fondeo Rechazado',
            'processing-documents': 'Procesando Documentos',
            activate: 'Activación',
            document: 'Carga de Documento',
            contact: 'Contacto',
            newsletter: 'Newsletter',
            'funding-complete': 'Fondeo Completado',
            'email-confirm': 'Confirmación Cuenta',
            'password-recovery': 'Recuperación Contraseña',
            'withdrawal-complete': 'Retiro Completado',
            'financing-submitted': 'Solicitud Financiamiento',
            'investment-complete': 'Inversión Completada',
            'investment-cancelled': 'Inversión Cancelada',
            patrimonial: 'Inversión Patrimonial',
            'investment-pending': 'Inversión Pendiente',
            birthday: 'Cumpleaños',
            internship: 'Becario',
            'investment-expired': 'Inversión Expirada',
            statement: 'Estado de Cuenta',
            'withdrawal-pending': 'Retiro Pendiente',
            unverified: 'Sin Verificar',
            'ebook-download': 'Descarga Ebook',
            'account-activation': 'Activación de Cuenta',
            'ebook-ebookcrowdfundinginmobiliario': 'Ebook Crowdfunding',
            'ebook-guiacrowdfunding': 'Ebook Guía Crowdfunding',
            'ebook-guiapracticainvertir': 'Ebook Guía Práctica',
        },
    },
    mati: {
        errors: {
            facematch:
                'Usuario no coincide con fotografía de identificación oficial.',
            liveness: 'No se detectó prueba de vida.',
            watchlists: 'Usuario se encuentra en listas internacionales.',
            'proof-of-residency': {
                'invalid-document': 'Comprobante de domicilio inválido.',
            },
        },
    },
    state: {
        Aguascalientes: 'AGS',
        'Baja California': 'BC',
        'Baja California Sur': 'BCS',
        Campeche: 'CAMP',
        Chiapas: 'CHIS',
        Chihuahua: 'CHIH',
        'Ciudad de México': 'CDMX',
        Coahuila: 'COAH',
        Colima: 'COL',
        Durango: 'DGO',
        Guanajuato: 'GTO',
        Guerrero: 'GRO',
        Hidalgo: 'HGO',
        Jalisco: 'JAL',
        México: 'MEX',
        Michoacán: 'MICH',
        Morelos: 'MOR',
        Nayarit: 'NAY',
        'Nuevo León': 'NL',
        Oaxaca: 'OAX',
        Puebla: 'PUE',
        Querétaro: 'QRO',
        'Quintana Roo': 'QROO',
        'San Luis Potosí': 'SLP',
        Sinaloa: 'SIN',
        Sonora: 'SON',
        Tabasco: 'TAB',
        Tamaulipas: 'TAMPS',
        Tlaxcala: 'TLAX',
        Veracruz: 'VER',
        Yucatán: 'YUC',
        Zacatecas: 'ZAC',
    },
};
