import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '@/context/authContext';
import { AuthContextType } from '@/@types/user/index.d';
import { ExpansiveLogin, SummaryUrl } from '@/@types/page-router';

const PriveRoute = () => {
    const { auth } = useContext(AuthContext) as AuthContextType;

    return auth.token ? <Outlet /> : <Navigate to={ExpansiveLogin} />;
};

export default PriveRoute;

export const OnLoginRouter = () => {
    const { auth } = useContext(AuthContext) as AuthContextType;

    return auth.token ? <Navigate to={SummaryUrl} /> : <Outlet />;
};
